<script>
import {Doughnut} from "vue-chartjs";
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";
import "chartjs-plugin-piechart-outlabels";
import {Chart as ChartJS} from 'chart.js'

ChartJS.defaults.global.defaultFontFamily = "Jost";
export default {
  extends: Doughnut,
  props: ["labels", "data", "cutout"],
  components: {
    ChartJSPluginDatalabels,
  },
  data() {
    return {};
  },

  mounted() {
    this.renderGraph();
  },

  computed: {
    chartData: function () {
      return this.data;
    },
  },
  watch: {
    data: function () {
      //this._chart.destroy();
      //this.renderChart(this.data, this.options);
      this.renderGraph();
    },
  },
  methods: {
    refresh() {
      //this._chart.destroy();
      //this.renderChart(this.data, this.options);
      this.renderGraph();
    },

    renderGraph() {
      let color = [
        "#ffab02",
        "#d60c56",
        "#2196f3",
        "#ff5252",
        "#ff3d00",
        "#64dd17",
        "#e91e63",
        "#579ba1", "#986b78", "#145a6a", "#d6bcf5", "#6e8e3b", "#5c51b1", "#b3e61c", "#421ec8", "#fcb790", "#2eece6", "#e42749", "#0b5313", "#f2c029", "#1c9820"
      ];

      this.addPlugin({
        beforeDraw: function (chart) {
          if (chart.config.options.elements.center) {
            // Get ctx from string
            var ctx = chart.chart.ctx;

            // Get options from the center object in options
            var centerConfig = chart.config.options.elements.center;
            var fontStyle = centerConfig.fontStyle || "Arial";
            var txt = centerConfig.text;
            var color = centerConfig.color || "#000";
            var maxFontSize = centerConfig.maxFontSize || 75;
            var sidePadding = centerConfig.sidePadding || 20;
            var sidePaddingCalculated =
                (sidePadding / 100) * (chart.innerRadius * 2);
            // Start with a base font of 30px
            ctx.font = "30px " + fontStyle;

            // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
            var stringWidth = ctx.measureText(txt).width;
            var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

            // Find out how much the font can grow in width.
            var widthRatio = elementWidth / stringWidth;
            var newFontSize = Math.floor(30 * widthRatio);
            var elementHeight = chart.innerRadius * 2;

            // Pick a new font size so it will not be larger than the height of label.
            var fontSizeToUse = Math.min(
                newFontSize,
                elementHeight,
                maxFontSize
            );
            var minFontSize = centerConfig.minFontSize;
            var lineHeight = centerConfig.lineHeight || 25;
            var wrapText = false;

            if (minFontSize === undefined) {
              minFontSize = 20;
            }

            if (minFontSize && fontSizeToUse < minFontSize) {
              fontSizeToUse = minFontSize;
              wrapText = true;
            }

            // Set font settings to draw it correctly.
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
            var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
            ctx.font = fontSizeToUse + "px " + fontStyle;
            ctx.fillStyle = color;

            if (!wrapText) {
              ctx.fillText(txt, centerX, centerY);
              return;
            }

            var words = txt.split(" ");
            var line = "";
            var lines = [];

            // Break words up into multiple lines if necessary
            for ( n = 0; n < words.length; n++) {
              var testLine = line + words[n] + " ";
              var metrics = ctx.measureText(testLine);
              var testWidth = metrics.width;
              if (testWidth > elementWidth && n > 0) {
                lines.push(line);
                line = words[n] + " ";
              } else {
                line = testLine;
              }
            }

            // Move the center up depending on line height and number of lines
            centerY -= (lines.length / 2) * lineHeight;

            for (var n = 0; n < lines.length; n++) {
              ctx.fillText(lines[n], centerX, centerY);
              centerY += lineHeight;
            }
            //Draw text in center
            ctx.fillText(line, centerX, centerY);
          }
        },
      });

      this.renderChart(
          {
            labels: this.labels,
            datasets: [{backgroundColor: color, data: this.data}],
            borderWidth: 5,
          },

          {
            responsive: true,
            maintainAspectRatio: false,
            cutoutPercentage: this.cutout,
            spacing: 80,

            elements: {
              arc: {
                borderWidth: 6,
              },
              center: {
                text: "",
                color: "#304156", // Default is #000000
                fontStyle: "Jost", // Default is Arial
                sidePadding: 20, // Default is 20 (as a percentage)
                minFontSize: 20, // Default is 20 (in px), set to false and text will not wrap.
                lineHeight: 25, // Default is 25 (in px), used for when text wraps
              },
            },
            legend: {
              display: true,
              position: "bottom",
              labels: {
                usePointStyle: true,
                padding: 25,
              },
            },
            plugins: {
              datalabels: {
                color: "white",
                textAlign: "center",
                font: {
                  weight: "bold",
                  size: 14,
                },
                formatter: function (value, context) {
                  var data = context.chart.data.datasets[0].data;
                  var sum = 0;
                  for (let index = 0; index < data.length; index++) {
                    sum = sum + data[index];
                  }
                  var percent = Math.round((value * 100 / sum) * 100) / 100;

                  return percent + "%";
                }
              },

            },
          }
      );
    },
  },
};
</script>