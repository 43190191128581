<template>
  <v-container fluid>
    <div class="main">
      <v-row v-if="statsLoading" justify="center">
        <v-col v-for="item in 4" :key="item" cols="6" lg="3">
          <v-card class="rounded-lg" flat>
            <v-card-text>
              <v-skeleton-loader type="article"></v-skeleton-loader>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else :align="'stretch'" justify="center">
        <!-- Par jour -->
        <v-col cols="12" lg="4" md="6">
          <v-sheet
              :rounded="'lg'"
              class="text-center d-flex align-center"
              style="height: 100%"
          >
            <v-card-title class="align-center text-center">
              <v-avatar
                  class="elevation-3 text-center"
                  color="success"
                  size="55"
                  style="margin: auto"
              >
                <v-icon color="white "> mdi-percent</v-icon>
              </v-avatar>
            </v-card-title>

            <v-card-text class="align-start align-center mt-4">
              <p class="font-weight-bold text-lg mb-1 mt-2">
                Consommation <br/>Par Jour
              </p>
              <p class="font-weight-black text-lg mb-1 title">
                {{ CurrencyFormatting(totals.stats.per_day) }} DZD
              </p>
            </v-card-text>
          </v-sheet>
        </v-col>

        <!-- Par week -->
        <v-col cols="12" lg="4" md="6">
          <v-sheet
              :rounded="'lg'"
              class="text-center d-flex align-center"
              style="height: 100%"
          >
            <v-card-title class="align-center text-center">
              <v-avatar
                  class="elevation-3 text-center"
                  color="success"
                  size="55"
                  style="margin: auto"
              >
                <v-icon color="white "> mdi-percent</v-icon>
              </v-avatar>
            </v-card-title>

            <v-card-text class="align-start align-center mt-4">
              <p class="font-weight-bold text-lg mb-1 mt-2">
                Consommation <br/>Par semaine
              </p>
              <p class="font-weight-black text-lg mb-1 title">
                {{ CurrencyFormatting(totals.stats.per_week) }} DZD
              </p>
            </v-card-text>
          </v-sheet>
        </v-col>

        <!-- Par mois -->
        <v-col cols="12" lg="4" md="6">
          <v-sheet
              :rounded="'lg'"
              class="text-center d-flex align-center"
              style="height: 100%"
          >
            <v-card-title class="align-center text-center">
              <v-avatar
                  class="elevation-3 text-center"
                  color="success"
                  size="55"
                  style="margin: auto"
              >
                <v-icon color="white "> mdi-percent</v-icon>
              </v-avatar>
            </v-card-title>

            <v-card-text class="align-start align-center mt-4">
              <p class="font-weight-bold text-lg mb-1 mt-2">
                Consommation <br/>Par mois
              </p>
              <p class="font-weight-black text-lg mb-1 title">
                {{ CurrencyFormatting(totals.stats.per_month) }} DZD
              </p>
            </v-card-text>
          </v-sheet>
        </v-col>

        <v-col cols="12" lg="4" md="6">
          <!-- Received -->
          <v-sheet
              :rounded="'lg'"
              class="text-center d-flex align-center"
              style="height: 100%"
          >
            <v-card-title class="align-center text-center">
              <v-avatar
                  class="elevation-3 text-center"
                  color="gifty"
                  size="55"
                  style="margin: auto"
              >
                <v-icon color="white "> mdi-currency-usd</v-icon>
              </v-avatar>
            </v-card-title>

            <v-card-text class="align-start">
              <p class="font-weight-bold text-lg mb-1">
                Solde Reçu par les POS
              </p>
              <p class="font-weight-black text-lg mb-1 title">
                {{ CurrencyFormatting(totals.received_refills) }}
                DZD
              </p>
            </v-card-text>
          </v-sheet>
        </v-col>

        <!-- Depensee -->
        <v-col cols="12" lg="4" md="6">
          <v-sheet
              :rounded="'lg'"
              class="text-center d-flex align-center"
              style="height: 100%"
          >
            <v-card-title class="align-center text-center">
              <v-avatar
                  class="elevation-3 text-center"
                  color="success"
                  size="45"
                  style="margin: auto"
              >
                <v-icon color="white "> mdi-currency-usd</v-icon>
              </v-avatar>
            </v-card-title>

            <v-card-text class="align-start mt-4">
              <p class="font-weight-bold text-lg mb-1">Solde Dépensé</p>
              <p class="font-weight-black text-lg mb-1 title">
                {{ CurrencyFormatting(totals.total_voucher) }}
                DZD
              </p>

              <div class="d-block mt-3">
                <v-progress-linear
                    :value="
                    getPercent(totals.total_voucher, totals.received_refills)
                  "
                    color="success accent-4"
                ></v-progress-linear>
                <p class="text--primary subtitle-1 mb-1 px-5 text-center">
                  {{
                    getPercent(totals.total_voucher, totals.received_refills)
                  }}
                  %
                </p>
              </div>
            </v-card-text>
          </v-sheet>
        </v-col>

        <!-- Restant -->
        <v-col cols="12" lg="4" md="6">
          <v-sheet
              :rounded="'lg'"
              class="text-center d-flex align-center"
              style="height: 100%"
          >
            <v-card-title class="align-center text-center">
              <v-avatar
                  class="elevation-3 text-center"
                  color="red"
                  size="45"
                  style="margin: auto"
              >
                <v-icon color="white "> mdi-currency-usd</v-icon>
              </v-avatar>
            </v-card-title>

            <v-card-text class="align-start mt-4">
              <p class="font-weight-bold text-lg mb-1">Solde Restant</p>
              <p class="font-weight-black text-lg mb-1 title">
                {{ CurrencyFormatting(totals.total_balance) }}
                DZD
              </p>
              <div class="d-block mt-3">
                <v-progress-linear
                    :value="
                    getPercent(totals.total_balance, totals.received_refills)
                  "
                    color="success accent-4"
                ></v-progress-linear>
                <p class="text--primary subtitle-1 mb-1 px-5 text-center">
                  {{
                    getPercent(totals.total_balance, totals.received_refills)
                  }}
                  %
                </p>
              </div>
            </v-card-text>
          </v-sheet>
        </v-col>

        <!-- Commission -->
        <v-col cols="12" lg="4" md="6">
          <v-sheet
              :rounded="'lg'"
              class="text-center d-flex align-center pt-5"
              style="height: 100%"
          >
            <v-card-title class="align-center text-center">
              <v-avatar
                  class="elevation-3 text-center"
                  color="primary"
                  size="45"
                  style="margin: auto"
              >
                <v-icon color="white "> mdi-currency-usd</v-icon>
              </v-avatar>
            </v-card-title>

            <v-card-text class="align-start">
              <p class="font-weight-bold text-lg mb-1">Total Commission</p>
              <p class="font-weight-black text-lg mb-1 title">
                {{ CurrencyFormatting(totals.total_commissions) }}
                DZD
              </p>
            </v-card-text>
          </v-sheet>
        </v-col>

        <!-- recover -->
        <v-col cols="12" lg="4" md="6">
          <v-sheet
              :rounded="'lg'"
              class="text-center d-flex align-center"
              style="height: 100%"
          >
            <v-card-title class="align-center text-center">
              <v-avatar
                  class="elevation-3 text-center al"
                  color="blue"
                  size="45"
                  style="margin: auto"
              >
                <v-icon color="white "> mdi-currency-usd</v-icon>
              </v-avatar>
            </v-card-title>

            <v-card-text class="align-start mt-4">
              <p class="font-weight-bold text-lg mb-1">Montant à recouvrir</p>
              <p class="font-weight-black text-lg mb-1 title">
                {{ CurrencyFormatting(totals.total_recover) }}
                DZD
              </p>
            </v-card-text>
          </v-sheet>
        </v-col>

        <!-- Comptes -->
        <v-col cols="12" lg="4" md="6">
          <v-sheet
              :rounded="'lg'"
              class="text-center d-flex align-center"
              style="height: 100%"
          >
            <v-card-title class="align-center text-center">
              <v-avatar
                  class="elevation-3 text-center"
                  color="gifty"
                  size="55"
                  style="margin: auto"
              >
                <v-icon color="white "> mdi-account</v-icon>
              </v-avatar>
            </v-card-title>

            <v-card-text class="align-start align-center mt-4">
              <p class="font-weight-bold text-lg mb-1 mt-2">
                Nombre de consommateurs
              </p>
              <p class="font-weight-black text-lg mb-1 title">
                {{ totals.number_clients }}
              </p>
            </v-card-text>
          </v-sheet>
        </v-col>
      </v-row>

      <v-row v-if="isLoading" justify="center">
        <v-col v-for="item in 2" :key="item" cols="6" lg="6">
          <v-card class="rounded-lg" flat>
            <v-card-text>
              <v-skeleton-loader type="article"></v-skeleton-loader>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" lg="6">
          <v-sheet :rounded="'lg'">
            <v-card-title class="align-start d-flex">
              <v-avatar class="elevation-3" color="info" size="38">
                <v-icon color="white"> mdi-ticket-percent</v-icon>
              </v-avatar>
              <span class="font-weight-bold text-center ml-3">
                Consommation par voucher (CA)</span
              >
            </v-card-title>

            <v-card-text class="align-start mt-2">
              <!-- <LineChart :labels="dashboardData.trasaction_evolutions.dates" :data="dashboardData.trasaction_evolutions.amounts" /> -->
              <DoughnutChart
                  v-if="totals.byDayGraph && totals.byDayGraph.labelPrices"
                  :data="totals.graphMontant"
                  :labels="totals.byDayGraph.labelPrices"
                  cutout="0"
              />
            </v-card-text>
          </v-sheet>
        </v-col>

        <v-col cols="12" lg="6">
          <v-sheet :rounded="'lg'">
            <v-card-title class="align-start d-flex">
              <v-avatar class="elevation-3" color="info" size="38">
                <v-icon color="white"> mdi-ticket-percent</v-icon>
              </v-avatar>
              <span class="font-weight-bold text-center ml-3">
                Consommation par voucher (Quantite)</span
              >
            </v-card-title>

            <v-card-text class="align-start mt-2">
              <!-- <LineChart :labels="dashboardData.trasaction_evolutions.dates" :data="dashboardData.trasaction_evolutions.amounts" /> -->
              <!-- <DoughnutChart
                  :labels="totals.byDayGraph.labelQuantity"
                  :data="totals.graphQuantity"
                  cutout="0"
                /> -->
              <Bar
                  v-if="totals.byDayGraph.labelQuantity.length < 7"
                  :data="totals.graphQuantity"
                  :labels="totals.byDayGraph.labelQuantity"
                  backgroundColor="#feaa01"
                  title="Consimmation par quantité"
              />
              <div v-else class="chartAreaWrapper">
                <Bar
                    v-if="widthGraph(totals.byDayGraph.labelQuantity) > 0"
                    :data="totals.graphQuantity"
                    :labels="totals.byDayGraph.labelQuantity"
                    :style="
                    'float: left; width:' +
                    widthGraph(totals.byDayGraph.labelQuantity) +
                    'px !important;'
                  "
                    backgroundColor="#feaa01"
                    title="Consimmation par quantité"
                />
              </div>
            </v-card-text>
          </v-sheet>
        </v-col>
      </v-row>

      <v-row v-if="isLoading" justify="center">
        <v-col v-for="item in 2" :key="item" cols="6" lg="6">
          <v-card class="rounded-lg" flat>
            <v-card-text>
              <v-skeleton-loader type="article"></v-skeleton-loader>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" lg="8">
          <v-sheet :rounded="'lg'">
            <v-card-title class="align-start d-flex">
              <v-avatar class="elevation-3" color="primary" size="38">
                <v-icon color="white"> mdi-update</v-icon>
              </v-avatar>
              <span class="font-weight-bold text-center ml-3">
                Consommation par jour (Montants)</span
              >
            </v-card-title>

            <v-card-text class="align-start mt-2">
              <!-- <LineChart :labels="dashboardData.trasaction_evolutions.dates" :data="dashboardData.trasaction_evolutions.amounts" /> -->
              <!-- <LineChart
                  :labels="totals.byDayGraph.days"
                  :data="totals.byDayGraph.totals"
                /> -->

              <Bar v-if="totals.byDayGraph.days.length < 7"
                   :data="totals.byDayGraph.totals"
                   :labels="totals.byDayGraph.days"
                   is_money="true"
                   title=" Consommation par jour (Montants)"
              />

              <div v-else class="chartAreaWrapper">
                <Bar
                    :data="totals.byDayGraph.totals"
                    :labels="totals.byDayGraph.days"
                    :style="
                    'float: left; width:' +
                    widthGraph(totals.byDayGraph.days) +
                    'px !important;'
                  "
                    is_money="true"
                    title=" Consommation par jour (Montants)"
                />
              </div>
            </v-card-text>
          </v-sheet>

          <v-sheet :rounded="'lg'" class="mt-3">
            <v-card-title class="align-start d-flex">
              <v-avatar class="elevation-3" color="primary" size="38">
                <v-icon color="white"> mdi-update</v-icon>
              </v-avatar>
              <span class="font-weight-bold text-center ml-3">
                Consommation par jour (Quantités)</span
              >
            </v-card-title>

            <v-card-text class="align-start mt-2">
              <Bar v-if="totals.byDayGraph.days.length < 7"
                   :data="totals.byDayGraph.quantities"
                   :labels="totals.byDayGraph.days"
                   title=" Consommation par jour (Quantités)"

              />
              <div v-else class="chartAreaWrapper">
                <Bar
                    :data="totals.byDayGraph.quantities"
                    :labels="totals.byDayGraph.days"
                    :style="
                    'float: left; width:' +
                    widthGraph(totals.byDayGraph.days) +
                    'px !important;'
                  "
                    title=" Consommation par jour (Quantités)"
                />
              </div>
            </v-card-text>
          </v-sheet>
        </v-col>
        <v-col cols="12" lg="4">
          <v-sheet :rounded="'lg'">
            <v-card-title class="align-start d-flex">
              <v-avatar class="elevation-3" color="primary" size="38">
                <v-icon color="white"> mdi-percent</v-icon>
              </v-avatar>
              <span class="font-weight-bold text-center ml-3"> Vouchers</span>
            </v-card-title>
            <v-card-text class="align-start mt-2">
              <div v-if="totals.vouchers.length > 0">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="font-weight-bold text-center">Montant</th>
                      <th class="font-weight-bold text-center">Quantite</th>
                      <th class="font-weight-bold text-center">Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in totals.vouchers" :key="item.id">
                      <td class="font-weight-bold text-center text-no-wrap">
                        {{ CurrencyFormatting(item.amount) }} DZD
                      </td>
                      <td class="font-weight-bold text-center text-no-wrap">
                        {{ item.quantity }}
                      </td>
                      <td
                          class="font-weight-bold text-right pink--text darken-2 text-no-wrap"
                      >
                        {{ CurrencyFormatting(item.total) }} DZD
                      </td>
                    </tr>
                    <tr style="border-top: 1px solid #333">
                      <td class="font-weight-bold text-right text-no-wrap">
                        <strong>Total :</strong>
                      </td>
                      <td class="font-weight-bold text-center text-no-wrap">
                        {{ CurrencyFormatting(totals.total_quantity) }}
                      </td>
                      <td class="font-weight-bold text-right text-no-wrap">
                        {{ CurrencyFormatting(totals.total_total) }} DZD
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div v-else class="text-center">
                <v-avatar size="200" tile>
                  <v-img :src="require('@/assets/database.svg')"></v-img>
                </v-avatar>
                <h3 class="text--primary d-block mb-2">OOPS !</h3>
                <p>Aucun enregistrement correspondant trouvé.</p>
              </div>
            </v-card-text>
          </v-sheet>
        </v-col>
      </v-row>

      <v-row v-if="isLoading" justify="center">
        <v-col v-for="item in 1" :key="item" cols="12" lg="12">
          <v-card class="rounded-lg" flat>
            <v-card-text>
              <v-skeleton-loader type="article"></v-skeleton-loader>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col cols="6">
          <v-sheet :rounded="'lg'">
            <v-card-title class="align-start d-flex">
              <v-avatar class="elevation-3" color="primary" size="38">
                <v-icon color="white"> mdi-clock</v-icon>
              </v-avatar>
              <span class="font-weight-bold text-center ml-3">
                Consommation par Semaine (Montants)</span
              >
            </v-card-title>

            <v-card-text class="align-start mt-2">

              <Bar v-if="totals.byWeekGraph.weeks.length < 7"
                   :data="totals.byWeekGraph.totals"
                   :labels="totals.byWeekGraph.weeks"
                   is_money="true"
                   title="Consommation par Semaine (Montants)"
              />

              <div v-else class="chartAreaWrapper">
                <Bar
                    :data="totals.byWeekGraph.totals"
                    :labels="totals.byWeekGraph.weeks"
                    :style="
                    'float: left; width:' +
                    widthGraph(totals.byWeekGraph.weeks) +
                    'px !important;'
                  "
                    is_money="true"
                    title="Consommation par Semaine (Montants)"
                />
              </div>
            </v-card-text>
          </v-sheet>
        </v-col>

        <v-col cols="6">
          <v-sheet :rounded="'lg'">
            <v-card-title class="align-start d-flex">
              <v-avatar class="elevation-3" color="primary" size="38">
                <v-icon color="white"> mdi-clock</v-icon>
              </v-avatar>
              <span class="font-weight-bold text-center ml-3">
                Consommation par Semaine (Quantités)</span
              >
            </v-card-title>

            <v-card-text class="align-start mt-2">
              <div class="chartAreaWrapper">
                <Bar
                    :data="totals.byWeekGraph.quantities"
                    :labels="totals.byWeekGraph.weeks"
                    :style="
                    'float: left; width:' +
                    widthGraph(totals.byWeekGraph.weeks) +
                    'px !important;'
                  "
                    title="Consommation par Semaine (Quantités)"
                />
              </div>
            </v-card-text>
          </v-sheet>
        </v-col>

        <v-col cols="6">
          <v-sheet :rounded="'lg'">
            <v-card-title class="align-start d-flex">
              <v-avatar class="elevation-3" color="primary" size="38">
                <v-icon color="white"> mdi-clock</v-icon>
              </v-avatar>
              <span class="font-weight-bold text-center ml-3">
                Consommation par Mois (Montants)</span
              >
            </v-card-title>

            <v-card-text class="align-start mt-2">
              <Bar v-if="totals.byMonthGraph.months.length < 7"
                   :data="totals.byMonthGraph.totals"
                   :labels="totals.byMonthGraph.months"
                   is_money="true"
                   title="Consommation par Mois (Montants)"

              />

              <div v-else class="chartAreaWrapper">
                <Bar
                    :data="totals.byMonthGraph.totals"
                    :labels="totals.byMonthGraph.months"
                    :style="
                    'float: left; width:' +
                    widthGraph(totals.byMonthGraph.months) +
                    'px !important;'
                  "
                    is_money="true"
                    title="Consommation par Mois (Montants)"
                />
              </div>
            </v-card-text>
          </v-sheet>
        </v-col>

        <v-col cols="6">
          <v-sheet :rounded="'lg'">
            <v-card-title class="align-start d-flex">
              <v-avatar class="elevation-3" color="primary" size="38">
                <v-icon color="white"> mdi-clock</v-icon>
              </v-avatar>
              <span class="font-weight-bold text-center ml-3">
                Consommation par Mois (Quantités)</span
              >
            </v-card-title>

            <v-card-text class="align-start mt-2">
              <Bar v-if="totals.byMonthGraph.months.length < 7"
                   :data="totals.byMonthGraph.quantities"
                   :labels="totals.byMonthGraph.months"
                   title="Consommation par Mois (Quantités)"

              />
              <div v-else class="chartAreaWrapper">
                <Bar
                    :data="totals.byMonthGraph.quantities"
                    :labels="totals.byMonthGraph.months"
                    :style="
                    'float: left; width:' +
                    widthGraph(totals.byMonthGraph.months) +
                    'px !important;'
                  "
                    title="Consommation par Mois (Quantités)"
                />
              </div>
            </v-card-text>
          </v-sheet>
        </v-col>

        <v-col cols="12">
          <v-sheet :rounded="'lg'">
            <v-card-title class="align-start d-flex">
              <v-avatar class="elevation-3" color="primary" size="38">
                <v-icon color="white"> mdi-clock</v-icon>
              </v-avatar>
              <span class="font-weight-bold text-center ml-3">
                Consommation par Heure</span
              >
            </v-card-title>

            <v-card-text class="align-start mt-2">

              <div class="chartAreaWrapper">
                <Bar
                    :data="totals.graphHours.values"
                    :is_money="true"
                    :labels="totals.graphHours.hours"
                    :style="
                    'float: left; width:' +
                    widthGraph(totals.graphHours.hours) +
                    'px !important;'
                  "
                    title="Consommation par heure"
                />
              </div>
            </v-card-text>
          </v-sheet>
        </v-col>
      </v-row>

      <v-row v-if="statsLoading" justify="center">
        <v-col v-for="item in 2" :key="item" cols="6" lg="6">
          <v-card class="rounded-lg" flat>
            <v-card-text>
              <v-skeleton-loader type="article"></v-skeleton-loader>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" lg="12">
          <v-card v-if="isLoading" class="rounded-lg" flat>
            <v-skeleton-loader type="table"></v-skeleton-loader>
          </v-card>

          <v-card v-else class="rounded-lg" flat>
            <v-card-text>
              <div class="d-flex justify-space-between mb-2">
                <div>
                  <v-btn
                      class="gifty white--text"
                      elevation="0"
                      rounded
                      @click="showDrivers()"
                  >
                    <v-icon class="mr-2">mdi-account-arrow-right</v-icon>
                    Consommateur
                  </v-btn>
                  <v-btn v-if="can('supplier')" class="ml-2" color="gifty" rounded text @click="exportStock()">
                    <v-icon left>mdi-download</v-icon>
                    Exporter le stock
                  </v-btn>
                </div>
                <div class="d-flex justify-space-between">
                  <FilterDialog :filter="filter" @filter="[filterBy($event)]"/>

                  <v-tooltip color="primary" top>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                          class="mr-3"
                          icon
                          v-bind="tooltip"
                          @click="[(filter = {}), fetchData()]"
                          v-on="{ ...tooltip }"
                      >
                        <v-icon>mdi-reload</v-icon>
                      </v-btn>
                    </template>
                    <span>Actualiser</span>
                  </v-tooltip>

                  <v-text-field
                      v-model="filter.keyword"
                      append-icon="mdi-magnify"
                      clearable
                      dense
                      filled
                      hide-details
                      placeholder="Rechercher..."
                      rounded
                      single-line
                      style="width: 300px"
                      v-on:keyup.enter="fetchData"
                  />
                </div>
              </div>
              <v-divider/>
              <div v-if="isLoading">
                <v-skeleton-loader type="table"></v-skeleton-loader>
              </div>
              <div v-else>
                <div v-if="totalss.data.length > 0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th
                            class="font-weight-bold"
                            style="cursor: pointer"
                            @click="orderBy('id')"
                        >
                          <div class="d-flex justify-space-between">
                            ID
                            <span>
                                <v-icon v-if="iconOrderDesc('id')" small
                                >mdi-arrow-down</v-icon
                                >
                                <v-icon v-if="iconOrderAsc('id')" small
                                >mdi-arrow-up</v-icon
                                >
                              </span>
                          </div>
                        </th>
                        <th class="font-weight-bold">Image</th>
                        <th class="font-weight-bold">Nom</th>
                        <th
                            class="font-weight-bold"
                            style="cursor: pointer"
                            @click="orderBy('total_received_refills')"
                        >
                          <div class="d-flex justify-space-between">
                            Total reçus
                            <span>
                                <v-icon
                                    v-if="iconOrderDesc('total_received_refills')"
                                    small
                                >mdi-arrow-up</v-icon
                                >
                                <v-icon
                                    v-if="iconOrderAsc('total_received_refills')"
                                    small
                                >mdi-arrow-down</v-icon
                                >
                              </span>
                          </div>
                        </th>
                        <th
                            class="font-weight-bold"
                            style="cursor: pointer"
                            @click="orderBy('total_voucher')"
                        >
                          <div class="d-flex justify-space-between">
                            Total dépensé
                            <span>
                                <v-icon
                                    v-if="iconOrderDesc('total_voucher')"
                                    small
                                >mdi-arrow-up</v-icon
                                >
                                <v-icon
                                    v-if="iconOrderAsc('total_voucher')"
                                    small
                                >mdi-arrow-down</v-icon
                                >
                              </span>
                          </div>
                        </th>

                        <th
                            class="font-weight-bold"
                            style="cursor: pointer"
                            @click="orderBy('sum_balance')"
                        >
                          <div class="d-flex justify-space-between">
                            Total restant
                            <span>
                                <v-icon
                                    v-if="iconOrderDesc('sum_balance')"
                                    small
                                >mdi-arrow-up</v-icon
                                >
                                <v-icon v-if="iconOrderAsc('sum_balance')" small
                                >mdi-arrow-down</v-icon
                                >
                              </span>
                          </div>
                        </th>
                        <th
                            class="font-weight-bold"
                            style="cursor: pointer"
                            @click="orderBy('total_commissions')"
                        >
                          <div class="d-flex justify-space-between">
                            Total commissions
                            <span>
                                <v-icon
                                    v-if="iconOrderDesc('total_commissions')"
                                    small
                                >mdi-arrow-up</v-icon
                                >
                                <v-icon
                                    v-if="iconOrderAsc('total_commissions')"
                                    small
                                >mdi-arrow-down</v-icon
                                >
                              </span>
                          </div>
                        </th>
                        <th
                            v-if="
                              in_array([1], $store.getters.getUser.user.role.id)
                            "
                            all_balance
                            class="font-weight-bold"
                            style="cursor: pointer"
                            @click="orderBy('total_recover')"
                        >
                          <div class="d-flex justify-space-between">
                            Total à recouvrir
                            <span>
                                <v-icon
                                    v-if="iconOrderDesc('total_recover')"
                                    small
                                >mdi-arrow-up</v-icon
                                >
                                <v-icon
                                    v-if="iconOrderAsc('total_recover')"
                                    small
                                >mdi-arrow-down</v-icon
                                >
                              </span>
                          </div>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                          v-for="item in totalss.data"
                          :key="item.id"
                          style="cursor: pointer"
                          @click="showAccount(item)"
                      >
                        <td class="font-weight-bold">
                          {{ item.id }}
                        </td>

                        <td class="font-weight-bold">
                          <v-avatar size="35">
                            <v-img :src="require('@/assets/avatar.png')"/>
                          </v-avatar>
                        </td>

                        <td class="font-weight-bold text-no-wrap">
                          {{ item.user.name }}
                        </td>

                        <td class="font-weight-bold text-right pink--text text-no-wrap">
                          {{ CurrencyFormatting(item.total_received_refills) }} DZD
                        </td>

                        <td
                            class="font-weight-bold text-right pink--text text-no-wrap"
                        >
                          {{ CurrencyFormatting(item.total_voucher) }} DZD
                        </td>

                        <td
                            class="font-weight-bold text-right pink--text text-no-wrap"
                        >
                          {{ CurrencyFormatting(item.total_balance) }} DZD
                        </td>

                        <td
                            class="font-weight-bold text-right pink--text text-no-wrap"
                        >
                          {{ CurrencyFormatting(item.total_commissions) }} DZD
                        </td>

                        <td
                            v-if="
                              in_array([1], $store.getters.getUser.user.role.id)
                            "
                            class="font-weight-bold text-right pink--text text-no-wrap"
                        >
                          {{ CurrencyFormatting(item.total_recover) }} DZD
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <div class="d-flex mt-4">
                    <span class="mt-2 mr-2">Lignes par page :</span>
                    <v-select
                        v-model="filter.size"
                        :items="[10, 20, 30, 40, 50]"
                        dense
                        filled
                        style="width: 20px"
                        @change="onPageChange"
                    ></v-select>
                    <v-spacer/>
                    <v-pagination
                        v-if="totalss.total > 15"
                        v-model="filter.page"
                        :length="Math.ceil(totalss.total / filter.size)"
                        circle
                        total-visible="6"
                        @input="onPageChange"
                    ></v-pagination>
                  </div>
                </div>
                <div v-else>
                  <div class="text-center">
                    <v-avatar size="200" tile>
                      <v-img :src="require('@/assets/database.svg')"></v-img>
                    </v-avatar>
                    <h3 class="text--primary d-block mb-2">OOPS !</h3>
                    <p>Aucun enregistrement correspondant trouvé.</p>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import FilterDialog from "./FilterDialog.vue";

import DoughnutChart from "@/components/charts/DoughnutChart.vue";
import Bar from "@/components/charts/Bar.vue";
import moment from "moment";

export default {
  components: {DoughnutChart, Bar, FilterDialog},
  data() {
    return {
      filter: {
        startDate: "",
        endDate: "",
        keyword: "",
        supplier_ids: [],
        size: 10,
        page: 1,

        // order
        order_by: "total_voucher",
        order_direction: "DESC",
      },
      pagination: {
        current: 1,
        total: 0,
      },
    };
  },

  methods: {
    fetchData() {
      this.filter.startDate = moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD");
      this.fetchAnalyses();
      this.fetchTotals();
    },
    fetchAnalyses() {
      this.$store.dispatch("fetchAnalysesVouchers", {
        filter: {
          ...this.filter,
          startDate: null,
          endDate: null,
        },
      });
    },
    fetchTotals() {
      this.$store.dispatch("fetchAnalysesTotalsVouchers", {
        filter: this.filter,
      });
    },
    getImageLink(file) {
      return process.env.VUE_APP_FILE_URL + file;
    },
    onPageChange() {
      this.fetchData();
    },

    filterBy(value) {
      this.filter.startDate = value.startDate;
      this.filter.endDate = value.endDate;
      this.filter.supplier_ids = value.supplier_ids;

      this.fetchData();
    },

    showAccount(item) {
      this.$router.push("/analyses_vouchers/show/" + item.id);
    },
    showDrivers() {
      this.$router.push("/analyses_vouchers/drivers");
    },

    orderBy(field) {
      if (this.filter.order_by == field) {
        if (this.filter.order_direction == "ASC") {
          this.filter.order_direction = "DESC";
        } else {
          this.filter.order_direction = "ASC";
        }
      } else {
        this.filter.order_by = field;
        this.filter.order_direction = "DESC";
      }
      this.filter.page = 1;
      this.fetchAnalyses();
    },
    iconOrderDesc(field) {
      if (
          this.filter.order_by == field &&
          this.filter.order_direction == "DESC"
      ) {
        return true;
      }
      return false;
    },
    iconOrderAsc(field) {
      if (
          this.filter.order_by == field &&
          this.filter.order_direction == "ASC"
      ) {
        return true;
      }
      return false;
    },
    widthGraph(table) {
      if (table) {
        return table.length * 70;
      }
      return 0;
    },
    exportStock() {
      let baseUrl = process.env.VUE_APP_FILE_URL
      let token = this.$store.state.user.access_token
      let userId = this.$store.state.user.user.id
      let DownloadURL = baseUrl + '/api/vouchers/exportSupplierVouchers?token=' + token + '&id=' + userId;
      window.open(DownloadURL, '_blanc')
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters.getLoading;
    },

    totalss() {
      return this.$store.getters.getAnalysesVouchers;
    },
    suppliers() {
      return this.$store.getters.getSuppliers;
    },

    totals() {
      return this.$store.getters.getStatsVouchers;
    },

    statsLoading() {
      return this.$store.getters.getStatsLoading;
    },
  },
  mounted() {
    this.$store.dispatch("fetchSuppliers");
    this.fetchData();
  },
};
</script>

<style scoped>
.chartAreaWrapper {
  width: 100%;
  overflow-x: scroll;
}
</style>